<template>
    <footer>
        <img src="../../static/img/cave2.jpg" class="cave" alt="cave">
        <ul class="footer-list">
            <li><a href="https://medium.com/@onisquad"> <img class="footer-img-resize" src="../../static/img/social/medium_black.png"  ></a></li>
            <li class="ml-25"><a href="https://opensea.io/collection/onisquad"> <img class="footer-img-resize" src="../../static/img/social/opensea_black.png"  ></a></li>
            <li class="ml-10"><a href="https://twitter.com/onisquadgg" target="_blank"><img src="../../static/img/social/nav_twitter.png" alt="Twitter icon"></a></li>
            <li><a href="https://discord.com/invite/onisquad"><img src="../../static/img/social/discord-plain.png" alt="Discord icon"></a></li>
        </ul>
        <p>@2021 Oni Squad. All rights reserved.</p>
        <p>Created by Yomi Games Pte Ltd.</p>
        <p><router-link to="/privacy-policy">Privacy Policy</router-link> and <router-link to="/terms-of-use">Terms of Use</router-link></p>
        <img src="../../static/img/demons.png" class="demons" alt="Oni">
        <img src="../../static/img/demons-m.png" class="demons-mobile" alt="Oni">
    </footer>
</template>
<style src="./Footer.css"></style>
<script src="./Footer.js"></script>