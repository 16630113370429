export default {
  name: 'Roadmap',
  data() {
    return {
      roadmap: [
        {
          id: 1,
          title: 'Q4 2021',
          description: [
            { complete: true, text: `Venture Capital Seed Round Complete.` },
            { complete: true, text: `Oni Mansion Game beta - Dec 8th at 3pm Pacific  (meet in our discord).` },
            { complete: true, text: `Partnerships with other NFT collections to play Oni Mansion Gaming Event.` },
            { complete: true, text: `Baby spirit NFT Drop for Oni holders.` },
          ],
        },
        {
          id: 2,
          title: 'Q1 2022',
          description: [
            { complete: true, text: `Testnet MATIC airdrop to all players - Jan 5th.` },
            { complete: true, text: `Beta #2 - Jan 6th @ 12PM Pacific.` },
            { complete: true, text: `Matic airdrop to all players - Jan 16th.` },
            { complete: true, text: `Oni Mansion Game launch - Jan 17th at 12PM Pacific.` },
            { complete: true, text: `Oni Music Drop - procedurally generated musical pieces.` },
          ],
        },
        // {
        //   id: 3,
        //   title: 'Q2 2022',
        //   description: [],
        // },
        {
          id: 4,
          title: 'Q3 2022',
          description: [
            { complete: false, text: `Vertical Slice Alpha of Yomi Conservatory (Game Two)` },
            { complete: false, text: `Governance Token Whitepaper` },
          ],
        },
        {
          id: 5,
          title: 'Q4 2022',
          description: [{ complete: false, text: `Game 2: play-2-earn game launch for android and web` }],
        },
      ],
    };
  },
};
