<template>
	<div>

		<Navbar></Navbar>
		<Banner></Banner>
		<div class="body-wrap">
			<div class="noise">
				<!-- <div id="mint" class="container">
					<h2>Mint</h2>
					<MintComponent></MintComponent>
				</div> -->
				<div id="features">
					<div class="container">

						<h2>Features</h2>
						<div class="features-grid">

							<div id="onis-flip">
								<img src="../../static/img/onis/onis-showcase-anim.gif" alt="Onis">
							</div>
							<div class="points-list">
								<div><p><span class="points">Unique</span> - Each Oni is unique and generated procedurally based on 242 traits</p>
								<p><span class="points">Rares</span> - All Oni are scary but some have rare traits that make them uniquely terrifying!</p>
								<p><span class="points">Hand Drawn</span> - All Oni are hand-drawn by top reddit artists</p>
								<p><span class="points">Governance Token Airdrop</span> - Oni Squad and Oni Mansion hodlers will be dropped governance tokens</p>
								<p><span class="points">Buffs</span> - Oni Squad and Oni Mansion hodlers will get in-game stat buffs in future games</p>
							</div>
						</div>
						</div>

					</div>

				</div>
				
				<Roadmap></Roadmap>
				<div id="team" class="container">
					<h2>Team</h2>
					<p class="team-desc">We are an accomplished team passionate about creating games with player owned economies.</p>

					<div class="profile-container">
						<div class="profile">

							<img src="../../static/img/onis/bio-pavan-oni.png" class="avatar" alt="Wizard Oni">

							<div class="profile-wrap">
								<h3>Pavan Katepalli</h3>

								<ul class="social-icons">
									<li><a href="https://twitter.com/pavankat" target="_blank"><img src="../../static/img/social/twitter_icon.png" alt="Twitter icon"></a></li>
									<li><a href="https://www.linkedin.com/in/pavankat" target="_blank"><img src="../../static/img/social/linkedin_icon.png" alt="Linkedin icon"></a></li>
								</ul>

								<ul class="bio-list">
									<li>CLO of Trilogy Education Services, <a href="https://techcrunch.com/2019/04/08/2u-trilogy/" target="_blank">$750m exit</a></li>
									<li>Blockchain Solutions Architect at ConsenSys (MetaMask and Gitcoin)</li>
									<li>Spoke at <a href="https://www.youtube.com/watch?v=ZA0Zj42hYfk&ab_channel=BitcoinMagazine" target="_blank">Bitcoin 2019</a>, <a href="https://blockworld2018.sched.com/event/EnjU" target="_blank">Blockworld 2018</a></li>
									<li>Startup Mentor at <a href="https://500.co/" target="_blank">500 Startups</a>, <a href="https://thecenter.nasdaq.org/" target="_blank">Nasdaq Entrepreneurial Center</a></li>
								</ul>
							</div>
						</div>

						<div class="profile">
							
							<img src="../../static/img/onis/bio-jason-oni.png" class="avatar" alt="Samurai Oni">

							<div class="profile-wrap">
								<h3>Jason Lee</h3>

								<ul class="social-icons">
									<li><a href="https://twitter.com/jasontwlee" target="_blank"><img src="../../static/img/social/twitter_icon.png" alt="Twitter icon"></a></li>
									<li><a href="https://www.linkedin.com/in/jasontwlee" target="_blank"><img src="../../static/img/social/linkedin_icon.png" alt="Linkedin icon"></a></li>
								</ul>

								<ul class="bio-list">
									<li>Head of Growth at <a href="https://omlet.gg/" target="_blank">Omlet Arcade</a>, a mobile game livestreaming platform. Grew from launch to 10+ million monthly active users and 70m+ app downloads</li>
									<li>Former Advisor at <a href="https://polygon.technology/" target="_blank">Polygon Studios</a>, the gaming and NFT division of Polygon</li>
									<li>Formerly Corporate Business Development & Strategy at Warner Bros</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Footer></Footer>
		<div id="popup" class="modal">
			<div class="modal-interior">
				<div class="modal-close">
					<span>X</span>
				</div>
				<div class="modal-content">

					<div class="contents">
						<div class="p-discord">
							<div class="p-btn">
								<a href="https://discord.com/invite/onisquad" target="_blank">Join the discord <img src="../../static/img/social/discord-plain.png" alt="Discord icon"></a>
							</div>
							
						</div>
						<div class="p-twitter">
							<div class="p-btn">
								<a href="https://twitter.com/onisquadgg" target="_blank">Follow on twitter <img src="../../static/img/social/nav_twitter.png" alt="Twitter icon"></a>
							</div>
							
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>
<!-- styles -->
<script src="./LandingPage.js"></script>
