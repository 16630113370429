import { Web3NFTService } from '../../services/Web3NFTService';
import { Clock } from './clock-creation';
import flipclock from '../../vendor/flipclock';

import MintComponent from '../../components/mint/Mint.vue';
import Navbar from '../../components/navbar/Navbar.vue';
import Footer from '../../components/footer/Footer.vue';
import Banner from '../../components/banner/Banner.vue';
import Roadmap from '../../components/roadmap/Roadmap.vue';

export default {
  name: 'LandingPage',
  components: {
    MintComponent,
    Navbar,
    Footer,
    Banner,
    Roadmap,
  },
  data() {
    return {
      balance: 0,

      /**
       * @type { Web3NFTService }
       */
      web3Config: new Web3NFTService(),
      account: null,
      connected: false,
    };
  },
  methods: {
    accountsWatch() {
      if (this.web3Config.provider) {
        this.web3Config.provider.on('accountsChanged', (accounts) => {
          this.account = accounts[0];
          this.web3Config.account = accounts[0];
          this.getBalance();
        });
        this.web3Config.provider.on('chainChanged', (accounts) => {
          this.balance = 0;
          if (!this.connected) {
            this.getWeb3Config();
          }
          this.getBalance();
        });
      }
    },
    methodsCall() {
      this.accountsWatch();
      this.getBalance();
    },
    getWeb3Config() {
      this.web3Config
        .getConfig()
        .then((config) => {
          this.account = config.accounts[0];
          this.methodsCall();
        })
        .catch((err) => {
          this.methodsCall();
          console.error(err);
        });
    },

    getBalance() {
      return new Promise((resolve, reject) => {
        this.web3Config
          .getBalance()
          .then((balance) => {
            this.balance = balance;
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async tokenOfOwnerByIndex() {
      this.web3Config.checkOwnerTokens(this.account).then((res) => {
        if (this.minted) {
          if (
            res.oniIDS.length > 0 &&
            parseInt(this.balance) === res.oniIDS.length &&
            !this.$router.currentRoute._value.fullPath.includes('minting')
          ) {
            this.$router.push({
              path: `/minting/${this.account}/${res.oniIDS.join(',')}`,
            });
          }
          this.minted = false;
        }
      });
    },
    reveal() {
      this.minted = true;
      this.tokenOfOwnerByIndex();
    },
  },
  computed: {
    /**
     * Checks if it MINT TIME!!!!
     * @returns {boolean}
     */
    enableMint() {
      const now = new Date();
      const expected = new Date(Date.UTC(2021, 10, 29, 14, 0, 0)).toLocaleString({ timeZone: 'America/Los_Angeles' });
      const PST_TIME = new Date(expected);
      return now.getTime() > PST_TIME.getTime();
    },
    maxOniLimit() {
      return this.balance < this.maxBalance;
    },
    mintCountAvailable() {
      return this.maxMint - this.currentCount;
    },
  },
  mounted() {
    this.getWeb3Config();
    new Clock();
  },
};
