<template>
    <div id="roadmap" class="container">
        <h2>Roadmap</h2>
        <div class="roadmap-container" >
            <span class="road-map-item" v-for="item in roadmap" :key="item.id">

                <h3 class="p-15">{{item.title}}</h3>
                <p class="border-bottom">
                    <div v-for="sub in item.description" ><img class="bullet-image" src="../../static/small-logo.png" alt="item bullet" /><span :class="{'line-through': sub.complete}" v-if="sub">{{sub.text}}</span></div>
                </p>
            </span>
        </div>

        <h2 class="header-small">Our Vision</h2>
        <p>Oni Squad will be the "Zynga of crypto". We are creating casual, play-to-earn games adapted from proven game types in the casual-mobile and play to earn games space. NFTs and tokens will be the primary rewards for players. We will innovate via the inherent composability of NFTs by bringing other NFT collections into our games, a focus on collaborative social gameplay, superior onboarding like our bridgeless and gasless flow for our first onchain game where users will be able to build an NFT for free. </p>

        <h2 class="header-small">Oni Squad NFTs</h2>
        <p>Oni NFTs will be important in future play to earn (p2e) games developed by Oni Squad. Future drops and rewards for Oni Squad NFT holders will be much more substantial in the future.</p>
        
        <h2 class="header-small">Oni Mansion Game</h2>
        <p>
            Our first game, Oni Mansion is the first of its kind where one can build their own NFT. The game will be hosted on Polygon (MATIC). We have developed technology for this game so players won't have to bridge their NFTs over from Ethereum to Polygon. We're also airdropping a small amount of MATIC to all players. This way, all players have to do is switch their network from Ethereum Mainnet to Polygon, and play the game without worrying about anything. By making this game as frictionless as possible to play, we're setting a new standard for the market to follow.
            <br><br>
            Oni Mansion NFTs will be important in future play to earn (p2e) games developed by Oni Squad.
            <br><br>
            Read more about the Oni Mansion game here: <a target="_blank" href="https://onisquad.medium.com/the-oni-mansion-game-d925c8f6364d">Oni Mansion Game</a>
        </p>

        
    </div>
</template>
<style src="./Roadmap.css"></style>
<script src="./Roadmap.js"></script>
