import jQuery from 'jquery';
export class Clock {
  constructor() {
    // popup
    var closedWindow = false;
    var scroll = 1;

    if (localStorage.getItem('socialPopup') === null) {
      jQuery(window).scroll(function(event) {
        scroll = jQuery(window).scrollTop();
        // console.log(scroll)
        if (scroll > 1000 && closedWindow == false) {
          jQuery('#popup')
            .fadeIn()
            .css('display', 'flex');
          jQuery('body').css('overflow', 'hidden');
          window.localStorage.setItem('socialPopup', true);
        }
      });
    }

    jQuery('.modal-close span').on('click', function() {
      jQuery('#popup').fadeOut();
      jQuery('body').css('overflow', 'auto');
      closedWindow = true;
    });

    jQuery('.modal').on('click', function(e) {
      if (e.target !== this) {
        return;
      } else {
        jQuery('#popup').fadeOut();
        jQuery('body').css('overflow', 'auto');
        closedWindow = true;
      }
    });

    // months are 0 based, so 9 is october instead of 10
    var rightNow = new Date();
    var endDate = new Date(Date.UTC(2021, 9, 29, 21, 0, 0, 0));

    var clock = jQuery('.clock').FlipClock({
      clockFace: 'DailyCounter',
      // autoStart:false,
      countdown: true,
      callbacks: {
        stop: function() {
          jQuery('.message').html('The clock has stopped!');
        },
      },
    });
    clock.setTime((endDate.getTime() - rightNow.getTime()) / 1000);

    // start the clock
    clock.start();

    //scroll to section from top navigation
    jQuery('nav .nav-links')
      .find('a')
      .click(function(e) {
        e.preventDefault();
        var section = jQuery(this).attr('href');
        jQuery('html, body').animate({
          scrollTop: jQuery(section).offset().top,
        });
      });
  }
}
